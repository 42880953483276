import styled, { css } from "styled-components"
import palette from "../../styles/styled-palette"

export const HomeStyled = styled.div``

export const HeroSection = styled.section`
  padding: 10em 10%;
  position: relative;
  ::after {
    position: absolute;
    content: " ";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0.5;
    background: transparent
      linear-gradient(226deg, #f8a4d833 0%, #6e64fe4d 100%, #6c63ff 100%) 0% 0%
      no-repeat padding-box;
  }

  @media (max-width: 768px) {
    padding: 10em 16px;
  }

  .main-hero {
    display: flex;

    @media (max-width: 1100px) {
      flex-wrap: wrap-reverse;
      justify-content: center;
    }

    article {
      @media (max-width: 1100px) {
        margin: 10em 0;
      }

      @media (max-width: 768px) {
        text-align: center;
      }

      h1 {
        font-size: 7.2em;
        line-height: 1.0416666666666667em;
        max-width: 12.805555555555555em;
        font-family: ${palette.fontSofiaProBold};
        color: ${palette.colorBlueDark};
        margin-bottom: 0.2222222222222222em;

        @media (max-width: 768px) {
          font-size: 6.2em;
          margin: auto;
          margin-bottom: 24px;
        }

        @media (max-width: 540px) {
          font-size: 5.2em;
        }
      }

      p {
        font-size: 3.5em;
        line-height: 1.2em;
        max-width: 21.457142857142856em;
        font-family: ${palette.fontSofiaProMedium};
        color: ${palette.colorBlueDark};

        @media (max-width: 768px) {
          font-size: 24px;
        }

        @media (max-width: 540px) {
          font-size: 20px;
        }
      }

      .container-form {
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-gap: 6.4em;
        max-width: 86.4em;
        width: 100%;
        margin-top: 5.5em;

        @media (max-width: 620px) {
          grid-template-columns: 1fr;
          grid-gap: 2em;
        }
      }

      .container-form form {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr max-content;

        @media (max-width: 500px) {
          grid-template-columns: 1fr;
        }
      }

      .container-form form input {
        font-size: 2em;
        line-height: 0.8em;
        color: ${palette.colorBlueDark};
        font-family: ${palette.fontSofia};
        border: none;
        outline: none;
        border-radius: 18px;
        height: 2.75em;
        padding: 0 3em 0 1em;
        width: 100%;
        ::placeholder {
          color: #9087b6;
        }
      }

      .container-form form button {
        font-size: 2em;
        line-height: 0.8em;
        color: #fdfdff;
        font-family: ${palette.fontSofia};
        background-color: ${palette.colorPurple};
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 28px;
        height: 2.75em;
        padding: 0 1em;
        margin-left: -2em;
        width: fit-content;

        @media (max-width: 500px) {
          margin-left: 0;
          width: 100%;
        }
      }

      .container-form > button,
      .container-form > a {
        display: flex;
        align-items: center;

        font-size: 2em;
        line-height: 0.8em;
        color: #fdfdff;
        font-family: ${palette.fontSofia};
        background-color: ${palette.colorPurple};
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 28px;
        height: 2.75em;
        padding: 0 1em;
        width: fit-content;
        /* font-size: 2em;
        line-height: 0.8em;
        border: solid 1.5px ${palette.colorPurple};
        color: ${palette.colorPurple};
        font-family: ${palette.fontSofia};
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border-radius: 28px;
        height: 2.75em;
        padding: 0 1em;
        /* margin-left: -2em; */
        width: fit-content;
        @media (max-width: 620px) {
          margin-left: 0;
        }
        */ @media (max-width: 500px) {
          width: 100%;
        }
      }
    }

    figure {
      max-width: 54em;
      height: auto;
    }

    figure img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  .section-list {
    max-width: 136em;
    width: 100%;
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 3.5em;
      line-height: 1.2em;
      font-family: ${palette.fontSemiSofia};
      color: ${palette.colorPurple};
      margin-bottom: 1.1428571428571428em;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      grid-gap: 1.6em;

      > span {
        height: 6.5em;
        padding: 0 3.2em;
        background-color: white;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > span p {
        font-size: 3.3em;
        line-height: 1.2121212121212122em;
        font-family: ${palette.fontSemiSofia};
        color: ${palette.colorBlueDark};
        margin-left: 0.4em;

        @media (max-width: 768px) {
          font-size: 24px;
        }

        @media (max-width: 540px) {
          font-size: 20px;
        }
      }
    }
  }
`

export const InfoSection = styled.section`
  padding: 18em 10% 0 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5em;
  align-items: center;
  overflow: hidden;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    padding: 10em 16px 0 16px;
  }

  article {
    max-width: 68.7em;
    width: 100%;
  }

  h4 {
    font-size: 2.3em;
    line-height: 1.2173913043478262em;
    font-family: ${palette.fontSofiaProMedium};
    color: ${palette.colorBlueDark};
    margin-bottom: 0.5217391304347826em;
  }

  h2 {
    font-size: 5.5em;
    line-height: 1.0909090909090908em;
    font-family: ${palette.fontSofiaProBold};
    color: ${palette.colorBlueDark};

    @media (max-width: 768px) {
      font-size: 4.2em;
    }

    @media (max-width: 540px) {
      font-size: 3.2em;
    }
  }

  figure {
    width: 140%;
    height: 100%;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }
`

export const CommunitySecction = styled.section`
  padding: 18em 10% 0 10%;

  @media (max-width: 768px) {
    padding: 18em 16px 0 16px;
  }

  h4 {
    font-size: 2.5em;
    line-height: 1.2em;
    font-family: ${palette.fontSofiaProBold};
    color: ${palette.colorPurple};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 1.92em;
    background-color: #f6f4ff;
    border-radius: 15px;
    width: fit-content;
    padding: 0 1em;

    @media (max-width: 768px) {
      font-size: 2em;
    }
  }

  h2 {
    font-size: 5.5em;
    line-height: 1.0909090909090908em;
    font-family: ${palette.fontSofiaProBold};
    color: ${palette.colorBlueDark};
    text-align: center;
    margin-top: 0.5em;

    @media (max-width: 768px) {
      font-size: 4.2em;
    }

    @media (max-width: 540px) {
      font-size: 3.2em;
    }
  }

  .communities-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 4.2em;
    margin-top: 9em;
    margin-bottom: 25em;

    article {
      width: 100%;
      max-width: 40em;
      height: 13em;
      background-color: #f9f7ff;
      border-radius: 16px;
      display: grid;
      grid-template-columns: min-content 1fr;
      grid-gap: 4em;
      align-items: center;
      padding: 2.1em;

      p {
        font-size: 2.5em;
        font-family: ${palette.fontSemiSofia};
        color: ${palette.colorBlueDark};

        @media (max-width: 768px) {
          font-size: 2em;
        }
      }

      > div {
        width: 9.3em;
        height: 9.3em;
        border-radius: 50%;
        img {
          width: 100%;
          object-fit: scale-down;
          border-radius: 50%;
        }
      }
    }
  }

  .reasons-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(36.6em, 36.6em));
    grid-gap: 5em;
    justify-content: space-evenly;
    align-content: space-between;
    max-width: 1220px;
    width: 100%;
    margin: auto;
    margin-top: 10em;

    article {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      > img,
      > div {
        width: 140px;
        height: 140px;
        background-color: ${palette.colorPurple};
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 6.5em;
      }

      h5 {
        font-size: 2.5em;
        font-family: ${palette.fontSofiaBold};
        line-height: 1.2em;
        color: #30383f;
        max-width: 9.92em;
        margin-bottom: 1.28em;
      }

      p {
        font-size: 2.2em;
        font-family: ${palette.fontSofia};
        line-height: 1.1818181818181819em;
        color: #30383f;
      }
    }
  }
`

const FigurePeopleImage = css`
  .people-image {
    max-width: 50.3em;
    width: 100%;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }
`

export const PeopleSection = styled.section`
  padding: 30em 10% 0 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5em;
  align-items: center;
  position: relative;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  @media (max-width: 768px) {
    padding: 30em 16px 0 16px;
  }

  .figure-image {
    position: absolute;
    z-index: -1;
    max-width: 63.1em;
    width: 100%;
    bottom: -55%;
    left: -24%;
  }

  ${FigurePeopleImage}
`

export const ItemArticle = styled.article`
  max-width: 60em;
  width: 100%;

  h5 {
    font-size: 2em;
    line-height: 1.2em;
    font-family: ${palette.fontSofiaProBold};
    color: ${palette.colorPurple};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.92em;
    background-color: #f6f4ff;
    border-radius: 15px;
    width: fit-content;
    padding: 0 1em;
    margin-bottom: 0.5em;
    ${({ left }) =>
      left
        ? css`
            margin-right: auto;
          `
        : css`
            margin-left: auto;
          `}

    .icon-label {
      margin-right: 0.3em;
    }
  }

  h2 {
    font-size: 5.5em;
    line-height: 1.0909090909090908em;
    font-family: ${palette.fontSofiaProBold};
    color: ${palette.colorBlueDark};
    margin-bottom: 0.7272727272727273em;

    @media (max-width: 768px) {
      font-size: 4.2em;
    }

    @media (max-width: 540px) {
      font-size: 3.2em;
    }

    ${({ left }) =>
      left
        ? css`
            text-align: start;
          `
        : css`
            text-align: end;
          `}

    img {
      max-width: 3.8363636363636364em;
      width: 100%;
      object-fit: scale-down;
      display: inline-block;
      margin-top: 0.7272727272727273em;
    }
  }

  p {
    font-size: 2.2em;
    line-height: 1.2727272727272727em;
    font-family: ${palette.fontSofiaProMedium};
    color: ${palette.colorBlueDark};
    text-align: end;
    margin-left: auto;
    max-width: 20em;

    ${({ left }) =>
      left
        ? css`
            text-align: start;
            margin: 0;
          `
        : css`
            text-align: end;
            margin-left: auto;
          `}
  }

  button,
  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    line-height: 0.8em;
    color: #fdfdff;
    font-family: ${palette.fontSofia};
    background-color: ${palette.colorPurple};
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 28px;
    height: 2.75em;
    padding: 0 1em;
    width: fit-content;
    margin-top: 2.45em;
  }
`

export const OportunitiesSection = styled.section`
  padding: 30em 10% 0 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5em;
  align-items: center;
  position: relative;

  figure {
    max-width: 63em;
    width: 100%;
    img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  @media (max-width: 768px) {
    padding: 30em 16px 0 16px;
  }
`

export const EventsSection = styled.section`
  padding: 30em 10% 0 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5em;
  align-items: center;
  position: relative;
  overflow: hidden;

  figure {
    max-width: 63em;
    width: 100%;
    img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  @media (max-width: 768px) {
    padding: 30em 16px 0 16px;
  }

  .figure-image {
    position: absolute;
    z-index: -1;
    max-width: 63.1em;
    width: 100%;
    bottom: 30%;
    right: -24%;
  }
`

export const AboutUsSection = styled.section`
  padding: 7.4em 10%;
  background: transparent
    linear-gradient(249deg, #f8a4d896 0%, #6e64fe4d 100%, #6c63ff 100%) 0% 0%
    no-repeat padding-box;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5em;
  overflow: hidden;
  margin-top: 23em;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  @media (max-width: 768px) {
    padding: 7.4em 16px;
  }

  figure {
    width: 135%;

    @media (max-width: 900px) {
      width: 100%;
    }

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }
`

export const ContactSection = styled.section`
  padding: 20em 10% 15em 10%;
  display: grid;
  grid-template-columns: 32em 1fr 32em;
  grid-gap: 3.2em;
  align-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  @media (max-width: 768px) {
    padding: 20em 16px 35em 16px;
  }

  figure {
    max-width: 32em;
    width: 100%;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  article {
    margin: auto;
    h2 {
      font-size: 8.8em;
      line-height: 1.0454545454545454em;
      font-family: ${palette.fontSofiaProBold};
      color: ${palette.colorBlueDark};
      text-align: center;
      margin-bottom: 0.5681818181818182em;

      @media (max-width: 768px) {
        font-size: 6.2em;
      }

      @media (max-width: 540px) {
        font-size: 5.4em;
      }
    }

    p {
      font-size: 3.5em;
      line-height: 1.2em;
      font-family: ${palette.fontSofiaProMedium};
      color: ${palette.colorBlueDark};
      text-align: center;
      margin-bottom: 1.6571428571428573em;
    }

    form {
      width: 100%;
      max-width: 56em;
      margin: auto;
      display: grid;
      grid-template-columns: 1fr max-content;

      @media (max-width: 500px) {
        grid-template-columns: 1fr;
      }
    }

    form input {
      font-size: 2em;
      line-height: 0.8em;
      color: ${palette.colorBlueDark};
      font-family: ${palette.fontSofia};
      border: none;
      outline: none;
      border-radius: 18px;
      height: 2.75em;
      padding: 0 3em 0 1em;
      width: 100%;
      background-color: #f6f4ff;
      ::placeholder {
        color: #9087b6;
      }
    }

    form button {
      font-size: 2em;
      line-height: 0.8em;
      color: #fdfdff;
      font-family: ${palette.fontSofia};
      background-color: ${palette.colorPurple};
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 28px;
      height: 2.75em;
      padding: 0 1em;
      margin-left: -2em;
      width: fit-content;

      @media (max-width: 500px) {
        margin-left: 0;
        width: 100%;
      }
    }

    a {
      display: flex;
      align-items: center;
      margin: auto;
      font-size: 2em;
      line-height: 0.8em;
      color: #fdfdff;
      font-family: ${palette.fontSofia};
      background-color: ${palette.colorPurple};
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 28px;
      height: 2.75em;
      padding: 0 1em;
      width: fit-content;
    }
  }
`
