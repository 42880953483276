import * as React from "react"

import {
  People,
  Briefcase,
  CalendarMonth,
  GiftCard,
} from "styled-icons/fluentui-system-regular"
import { PlusOutline } from "styled-icons/evaicons-outline"
import { ArrowRight } from "styled-icons/bootstrap"
import {
  AboutUsSection,
  CommunitySecction,
  ContactSection,
  EventsSection,
  HeroSection,
  HomeStyled,
  InfoSection,
  ItemArticle,
  OportunitiesSection,
  PeopleSection,
} from "../styles/pages/home"

import imageHero from "../images/hero-image.png"
import infoImage from "../images/info-image.png"
import peopleImage from "../images/people-image.png"
import figurePeopleImage from "../images/figure-people-image.png"
import figureOportunitiesImage from "../images/figure-oportunities-image.png"
import figureEventsImage from "../images/figure-events-image.png"
import figureBenefitsImage from "../images/figure-benefits-image.png"
import logo from "../images/logo-lapieza-communities.png"
import teamLaPiezaImage from "../images/team-lapieza-image.png"
import contactImageOne from "../images/contact-image-one.png"
import contactImageTwo from "../images/contact-image-two.png"
import figureEventeImage from "../images/figure-evente-image.png"
import palette from "../styles/styled-palette"

import edu from "../images/Edu@3x.png"
import startup from "../images/Start Ups@3x.png"
import more from "../images/Grupo 1551@3x.png"
import interst from "../images/Intereses@3x.png"
import indus from "../images/Industrias@3x.png"

import control from "../images/Control@3x.png"
import comu from "../images/Comunicación@3x.png"
import inter from "../images/Interacciones@3x.png"

const IndexPage = () => (
  <HomeStyled>
    <HeroSection>
      <div className="main-hero">
        <article>
          <h1>El lugar para hacer networking entre comunidades</h1>
          <p>
            Conecta con personas con intereses comunes y comparte oportunidades
            con tu comunidad.
          </p>
          <div className="container-form">
            {/* <form>
              <input type="text" placeholder="Ingresa tu correo" />
              <button>Regístrate</button>
            </form> */}
            <a
              href="https://communities.lapieza.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Regístrate ahora
            </a>
            {/* <button>Conoce más</button> */}
          </div>
        </article>
        <figure>
          <img src={imageHero} alt="Networking | LaPieza.io" />
        </figure>
      </div>
      <div className="section-list">
        <h4>La nueva forma de conectar:</h4>
        <div>
          <span>
            <People size={32} color={palette.colorPurple} />
            <p>Personas</p>
          </span>
          <span>
            <Briefcase size={32} color={palette.colorPurple} />
            <p>Oportunidades</p>
          </span>
          <span>
            <CalendarMonth size={32} color={palette.colorPurple} />
            <p>Eventos</p>
          </span>
          <span>
            <GiftCard size={32} color={palette.colorPurple} />
            <p>Beneficios</p>
          </span>
          <span>
            <PlusOutline size={32} color={palette.colorPurple} />
            <p>Y más</p>
          </span>
        </div>
      </div>
    </HeroSection>
    <InfoSection>
      <article>
        <h4>¿Qué es Communities?</h4>
        <h2>
          Una plataforma gratuita para llevar a tu comunidad al siguiente nivel
        </h2>
      </article>
      <figure>
        <img src={infoImage} alt="Academy | LaPieza.io" />
      </figure>
    </InfoSection>
    <CommunitySecction>
      <h4>Tipos de comunidades</h4>
      <h2>Un espacio para personas como tú</h2>
      <div className="communities-list">
        <article>
          <div>
            <img src={edu} alt="Benefit | LaPieza.io" />
          </div>
          <p>Universidades</p>
        </article>
        <article>
          <div>
            <img src={startup} alt="Benefit | LaPieza.io" />
          </div>
          <p>Startups</p>
        </article>
        <article>
          <div>
            <img src={interst} alt="Benefit | LaPieza.io" />
          </div>
          <p>Intereses</p>
        </article>
        {/* <article>
          <div>
            <img src="" alt="Benefit | LaPieza.io" />
          </div>
          <p>Clubs</p>
        </article> */}
        <article>
          <div>
            <img src={indus} alt="Benefit | LaPieza.io" />
          </div>
          <p>Industrias</p>
        </article>
        <article>
          <div>
            <img src={more} alt="Benefit | LaPieza.io" />
          </div>
          <p>Muchas más</p>
        </article>
      </div>
      <h4>¿Por qué LaPieza Communities?</h4>
      <div className="reasons-list">
        <article>
          <img src={control} alt="Benefit | LaPieza.io" />
          <h5>Crea y administra tus comunidades</h5>
          <p>
            Cualquier persona podrá crear comunidades, así como administrar los
            permisos y usuarios de las mismas.
          </p>
        </article>
        <article>
          <img src={comu} alt="Benefit | LaPieza.io" />
          <h5>
            Contacta a personas de las comunidades de las que formas parte
          </h5>
          <p>
            ¿Te interesa conocer a alguien? ¡Conecta con la persona! Podrás
            escribirle por WhatsApp o correo electrónico.
          </p>
        </article>
        <article>
          <img src={inter} alt="Benefit | LaPieza.io" />
          <h5>Interacciones que trascienden</h5>
          <p>
            Interactúa y comparte novedades con tu comunidad pública o privada.
          </p>
        </article>
      </div>
    </CommunitySecction>
    <PeopleSection>
      <figure className="people-image">
        <img src={peopleImage} alt="Figura | LaPieza" />
      </figure>
      <ItemArticle>
        <h5>
          <People
            className="icon-label"
            size={24}
            color={palette.colorPurple}
          />
          Personas
        </h5>
        <h2>Una nueva forma de conectar</h2>
        <p>
          El networking salva vidas. Contacta a las personas de tus comunidades
          y crece tu red de contactos.
        </p>
      </ItemArticle>
      <figure className="figure-image">
        <img src={figurePeopleImage} alt="Networkin | LaPieza" />
      </figure>
    </PeopleSection>
    <OportunitiesSection>
      <ItemArticle left>
        <h5>
          <Briefcase size={24} color={palette.colorPurple} /> Oportunidades
        </h5>
        <h2>¿En tu empresa están buscando talento?</h2>
        <p>
          Publica vacantes de empleo de forma gratuita para que las personas de
          tu comunidad puedan aplicar.
        </p>
      </ItemArticle>
      <figure>
        <img src={figureOportunitiesImage} alt="Oportunidades | LaPieza" />
      </figure>
    </OportunitiesSection>
    <EventsSection>
      <figure>
        <img src={figureEventsImage} alt="Eventos | LaPieza" />
      </figure>
      <ItemArticle>
        <h5>
          <CalendarMonth size={24} color={palette.colorPurple} />
          Eventos
        </h5>
        <h2>Comparte tus mejores eventos</h2>
        <p>
          Si tienes eventos en puerta que pueden interesarle a la comunidad,
          comparte los detalles para que puedan asistir.
        </p>
      </ItemArticle>
      <figure className="figure-image">
        <img src={figureEventeImage} alt="Figura | LaPieza" />
      </figure>
    </EventsSection>
    <OportunitiesSection>
      <ItemArticle left>
        <h5>
          <GiftCard size={24} color={palette.colorPurple} />
          Beneficios
        </h5>
        <h2>Otorga beneficios</h2>
        <p>
          ¿A quién no le encantan los descuentos y precios especiales? Comparte
          beneficios con tu comunidad, desde descuentos en restaurantes hasta
          códigos de descuento en tu app favorita.
        </p>
      </ItemArticle>
      <figure>
        <img src={figureBenefitsImage} alt="Beneficios | LaPieza" />
      </figure>
    </OportunitiesSection>
    <AboutUsSection id="aboutus">
      <ItemArticle left>
        <h5>¿Quiénes somos?</h5>
        <h2>
          <img src={logo} alt="Logo | LaPieza" />, el equipo detrás de esta
          plataforma
        </h2>
        <p>
          Somos un equipo de emprendedores que busca hacer un impacto social
          impulsando la empleabilidad en América Latina.
        </p>
        <a
          href="https://lapieza.io/es/about-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          Conoce LaPieza
          <ArrowRight size={24} />
        </a>
      </ItemArticle>
      <figure>
        <img src={teamLaPiezaImage} alt="Team | LaPieza" />
      </figure>
    </AboutUsSection>
    <ContactSection>
      <figure>
        <img src={contactImageOne} alt="People | LaPieza" />
      </figure>
      <article>
        <h2>Comienza ahora</h2>
        <p>Regístrate y crea tu primera comunidad. ¡No tiene ningún costo!</p>
        <a
          href="https://communities.lapieza.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Regístrate ahora
        </a>
        {/* <form>
          <input type="text" placeholder="Ingresa tu correo" />
          <button>Únete</button>
        </form> */}
      </article>
      <figure>
        <img src={contactImageTwo} alt="People | LaPieza" />
      </figure>
    </ContactSection>
  </HomeStyled>
)

export default IndexPage
